import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { Ref, computed, onUnmounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { SingleSideDepositForm } from '@/store/modules/single-side/models/single-side-deposit-form';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import {
  INotification,
  INotificationStep,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { fromWei, getScanLink, toWei } from '@/sdk/utils';
import { safeParseUnits } from '@/helpers/utils';
import { getErc20Contract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { getLiquidityDelegateAddress } from '@/helpers/address.helper';
import { SingleSideToken } from '@/views/pages/liquidity/single-side/models/single-side-token';
import { BIG_ONE, BIG_ZERO, max } from '@/utils/bigNumber';
import { Portfolio } from '@/sdk/entities/portfolio';
import { PortfolioFarm } from '@/sdk/entities/portfolioFarm';
import { Token } from '@/sdk/entities/token';
import { usePortfolioEstimateDeposit } from '@/composables/portfolio/usePortfolioEstimateDeposit';
import { useSingleSideDepositTransaction } from '@/composables/single-side/useSingleSideDepositTransaction';
import { LOSS_LIMIT } from './constants/LOSS_LIMIT';
import { useFarmingTokens } from '../tokens/useFarmingTokens';
import { ChainId } from '@/sdk/constants';
import { TRANSACTION_FEE_NATIVE } from '@/constants/TRANSACTION_FEE_NATIVE';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import { useSingleSideMilkomedaWSCBridge } from '@/store/modules/single-side/useSingleSideMilkomedaWSCBridge';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';

export const useSingleSideDeposit = () => {
  const { t } = useI18n();
  const { walletState } = useEVMWallet();
  const { updateFarmingToken } = useFarmingTokens();
  const { fetchExactLpWeiAmountAfterDeposit } = usePortfolioEstimateDeposit();
  const { getDelegateDepositMethodCallArgs, createDepositTransaction } =
    useSingleSideDepositTransaction();
  const {
    milkomedaWSCBridgeState,
    setIsEVMFromCardano,
    setBridgeTokensFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetWSCBridge,
  } = useSingleSideMilkomedaWSCBridge();

  const farm = ref<PortfolioFarm | null>();
  const portfolio = ref<Portfolio | null>();
  const updateFormIntervalId = ref<NodeJS.Timer>();

  const setFarm = (newFarm: PortfolioFarm) => {
    farm.value = newFarm;
    portfolio.value = newFarm.portfolio;
  };

  const singleSideForm = reactive<SingleSideDepositForm>({
    isWalletConnected: false,
    isBalanceLoading: true,
    balance: null,
    input: {
      token: null,
      amount: null,
    },
    isEstimateDepositRequestInProgress: false,
    estimatedDepositInLP: BIG_ZERO,
    estimatedDepositInUSD: BIG_ZERO,
    lossInUSD: BIG_ZERO,
    lossPercent: BIG_ZERO,
    isAllowanceRequestInProgress: false,
    hasAllowance: undefined,
    errors: {},
  });

  // BASE WEI / TOKEN WEI
  const depositPrice = computed(() => {
    if (!singleSideForm.input.token) return undefined;
    if (!portfolio.value) return undefined;

    const portfolioToken = singleSideForm.input.token.portfolioToken;
    const tokenInfo = portfolio.value.tokensInfoByAddr[portfolioToken.address];

    return tokenInfo
      .getDepositPrice()
      .shiftedBy(portfolio.value.baseToken.decimals - tokenInfo.token.decimals);
  });

  // BASE WEI / LP WEI
  const lpTokenPriceBase = computed(() => {
    if (!portfolio.value) return undefined;

    // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.value.lpToken.decimals
    return portfolio.value.lpTokenPriceBase.shiftedBy(
      portfolio.value.baseToken.decimals - portfolio.value.lpToken.decimals,
    );
  });

  // USD / BASE
  const baseTokenPriceInUSD = computed(() => {
    if (!portfolio.value) return undefined;

    return portfolio.value.priceInUSD;
  });

  const { addNotification, removeNotificationById } = useNotifications();

  const getApproveNotificationOptions = (
    status: NotificationStatus,
    explorer?: string,
  ): INotification => {
    const portfolioToken = singleSideForm.input.token?.portfolioToken;
    return {
      id: `approve_${portfolioToken?.symbol}`,
      status: status,
      content: t(`singleSide.notificationContent.approve.${status}`, {
        token: portfolioToken?.symbol,
      }),
      explorerLink: explorer,
    };
  };

  const getDepositNotificationOptions = (options: {
    status: NotificationStatus;
    id: string;
    portfolioName: string;
    step?: INotificationStep;
    hideExplorerLink?: boolean;
    explorerChainId?: ChainId;
  }): INotification => {
    const showLink = options.id && !options.hideExplorerLink;
    const hashTx = options.id;
    const explorerChainId = options.explorerChainId ?? options.step?.chainId;
    return {
      ...options,
      content: t(`singleSide.notificationContent.deposit.${options.status}`, {
        portfolioName: options.portfolioName,
      }),
      explorerLink: showLink ? getScanLink(hashTx, 'transaction', explorerChainId) : undefined,
    };
  };

  const resetEstimateDeposit = () => {
    singleSideForm.estimatedDepositInLP = BIG_ZERO;
    singleSideForm.estimatedDepositInUSD = BIG_ZERO;
    singleSideForm.lossInUSD = BIG_ZERO;
    singleSideForm.lossPercent = BIG_ZERO;
  };

  const $reset = (): void => {
    resetEstimateDeposit();
    singleSideForm.input.amount = null;
    singleSideForm.isEstimateDepositRequestInProgress = false;
    singleSideForm.hasAllowance = undefined;
    singleSideForm.isAllowanceRequestInProgress = false;
    singleSideForm.errors = {};
  };

  const validate = () => {
    singleSideForm.errors = {};
    const { balance } = singleSideForm;
    const { token, amount } = singleSideForm.input;

    if (!token) singleSideForm.errors['PORTFOLIO_REQUIRED'] = true;
    if (!token) singleSideForm.errors['TOKEN_REQUIRED'] = true;
    if (!amount) singleSideForm.errors['AMOUNT_REQUIRED'] = true;
    if (amount && +amount === 0) singleSideForm.errors['AMOUNT_IS_ZERO'] = true;
    if (!balance) singleSideForm.errors['BALANCE_REQUIRED'] = true;
    if (amount && balance && BigNumber(amount).gt(balance)) {
      singleSideForm.errors['INSUFFICIENTLY_BALANCE'] = true;
    }
    if (
      ENABLE_FAKE_CARDANO_NETWORK &&
      amount &&
      balance &&
      milkomedaWSCBridgeState.needBridge &&
      !milkomedaWSCBridgeState.isValidCardanoBalance
    ) {
      singleSideForm.errors['INSUFFICIENTLY_BALANCE'] = true;
    }
    if (
      amount &&
      singleSideForm.input.token &&
      BigNumber(amount).gt(singleSideForm.input.token.noFeeLimit)
    ) {
      singleSideForm.errors['EXCEEDED_NO_FEE_LIMIT'] = true;
    }
    if (singleSideForm.lossPercent.gt(LOSS_LIMIT)) {
      singleSideForm.errors['HUGE_LOSS'] = true;
    }
  };

  const setToken = (token: SingleSideToken): void => {
    singleSideForm.input.token = token;
  };

  const setAmount = (amount: string | null): void => {
    const isAmountIsNotEmpty = !amount || amount.length > 0 || +amount !== 0;
    if (isAmountIsNotEmpty) {
      singleSideForm.input.amount = amount;
    } else {
      singleSideForm.input.amount = null;
    }
  };

  const checkAllowance = async () => {
    console.log('[SINGLE SIDE DEPOSIT] checkAllowance : ', singleSideForm.input.token);

    if (!singleSideForm.input.token) return false;
    if (!singleSideForm.input.amount) return false;

    const portfolioToken = singleSideForm.input.token.portfolioToken;

    if (portfolioToken.isBaseToken()) {
      console.log('[SINGLE SIDE DEPOSIT] checkAllowance (base token) : ', true);
      singleSideForm.hasAllowance = true;
      return true;
    }

    try {
      const allowanceResult = await checkERC20Allowance(
        portfolioToken.address,
        walletState.account ?? '',
      );
      const allowanceAmount = fromWei(
        allowanceResult.toString(),
        portfolioToken.decimals,
      ).toString();
      console.groupCollapsed(
        '[SINGLE SIDE DEPOSIT] checkAllowance : ',
        +allowanceAmount >= +singleSideForm.input.amount,
      );
      console.log('allowanceResult : ', allowanceResult.toString());
      console.log('allowanceAmount : ', allowanceAmount);
      console.log('entered amount : ', singleSideForm.input.amount);
      console.groupEnd();
      singleSideForm.hasAllowance = +allowanceAmount >= +singleSideForm.input.amount;
    } catch (e) {
      singleSideForm.hasAllowance = false;
      throw Error(e);
    }
  };

  const setAllowance = async () => {
    console.log('[SINGLE SIDE DEPOSIT] : setAllowance');

    if (!singleSideForm.input.token) return false;
    if (!singleSideForm.input.amount) return false;

    singleSideForm.isAllowanceRequestInProgress = true;
    const portfolioToken = singleSideForm.input.token.portfolioToken;
    try {
      addNotification(getApproveNotificationOptions('inProgress'));

      const amount = safeParseUnits(
        singleSideForm.input.amount,
        portfolioToken.decimals,
      ).toString();

      const result = await approve(portfolioToken.address, amount);
      await result.wait();

      const explorerLink = getScanLink(result.hash, 'transaction');
      addNotification(getApproveNotificationOptions('success', explorerLink));

      await checkAllowance();
    } catch (error) {
      addNotification(getApproveNotificationOptions('error'));

      throw Error(error);
    } finally {
      singleSideForm.isAllowanceRequestInProgress = false;
    }
  };

  const setMax = () => {
    if (!singleSideForm.input.token) return;

    const token = singleSideForm.input.token;
    const maxFractionDigits = token.portfolioToken.decimals;
    let maxBalance = token.balance;

    if (!maxBalance) {
      singleSideForm.input.amount = '';
      return;
    }

    if (token.portfolioToken.isETHToken()) {
      maxBalance = maxBalance.minus(TRANSACTION_FEE_NATIVE);
    }

    singleSideForm.input.amount = maxBalance.toFixed(maxFractionDigits, BigNumber.ROUND_DOWN);
  };

  const estimateDeposit = async () => {
    console.log('[SINGLE SIDE DEPOSIT] : estimateDeposit');

    if (!singleSideForm.input.token) return false;
    if (!singleSideForm.input.amount) return false;
    if (!portfolio.value) return false;

    if (singleSideForm.isEstimateDepositRequestInProgress) return false;

    singleSideForm.isEstimateDepositRequestInProgress = true;
    try {
      const token = singleSideForm.input.token.portfolioToken;
      const selectedTokens = [
        {
          token: token,
          amount: BigNumber(singleSideForm.input.amount),
        },
      ];
      const lpAmountDepositInWei = await fetchExactLpWeiAmountAfterDeposit(
        singleSideForm.input.token.portfolio.address,
        selectedTokens,
      );

      // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.value.lpToken.decimals
      singleSideForm.estimatedDepositInLP = fromWei(
        lpAmountDepositInWei,
        portfolio.value.lpToken.decimals,
      );
    } catch (err) {
      console.error('[SINGLE SIDE DEPOSIT] Estimate deposit error : ', err);
      singleSideForm.errors['PRICE_IMPACT'] = true;
    } finally {
      singleSideForm.isEstimateDepositRequestInProgress = false;
    }
  };

  const doDeposit = async () => {
    console.log('[SINGLE SIDE DEPOSIT] deposit');

    if (!singleSideForm.input.token) {
      console.warn('[SINGLE SIDE DEPOSIT] Can not do deposit, cause no token.');
      return false;
    }
    if (!singleSideForm.input.amount) {
      console.warn('[SINGLE SIDE DEPOSIT] Can not do deposit, cause amount is empty.');
      return false;
    }
    if (!portfolio.value) {
      console.warn('[SINGLE SIDE DEPOSIT] Can not do deposit, cause portfolio unknown.');
      return false;
    }
    if (!farm.value) {
      console.warn('[SINGLE SIDE DEPOSIT] Can not do deposit, cause farm unknown.');
      return false;
    }

    const portfolioName = portfolio.value.name;

    let id = 'single-side-deposit';
    // NOTE: will need for cross chain.
    const step: INotificationStep = {
      current: 1,
      total: 2,
      chainId: singleSideForm.input.token?.portfolioToken.chainId,
    };
    const isCrossChain = singleSideForm.input.token.isCrossChain;

    const portfolioToken = singleSideForm.input.token.portfolioToken;
    const selectedToken = {
      token: portfolioToken,
      amount: BigNumber(singleSideForm.input.amount),
    };

    // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.value.lpToken.decimals
    const callArgs = getDelegateDepositMethodCallArgs({
      selectedTokens: [selectedToken],
      lpAmountOutInWei: toWei(
        singleSideForm.estimatedDepositInLP.toString(),
        portfolio.value.lpToken.decimals,
      ),
      portfolioAddress: portfolio.value.contractAddress,
      farmAddress: farm.value.farm,
    });
    // if doing deposit of native token
    const isBaseToken = portfolioToken.isBaseToken();
    const msgValue = isBaseToken ? callArgs.amountsIn[0] : 0;
    const overrides = {
      value: msgValue,
    };

    try {
      addNotification(
        getDepositNotificationOptions({
          id,
          status: 'inProgress',
          portfolioName,
          step: isCrossChain ? { ...step } : undefined,
          hideExplorerLink: true,
        }),
      );
      const transactionResponse = await createDepositTransaction(callArgs, overrides);
      removeNotificationById(id);

      id = transactionResponse.hash;
      addNotification(
        getDepositNotificationOptions({
          id,
          status: 'inProgress',
          portfolioName,
          step: isCrossChain ? { ...step } : undefined,
        }),
      );

      $reset();

      await transactionResponse.wait();

      addNotification(
        getDepositNotificationOptions({
          id,
          status: 'success',
          portfolioName,
          step: isCrossChain ? { ...step } : undefined,
          explorerChainId:
            !isCrossChain && ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined,
          hideExplorerLink: !isCrossChain && ENABLE_FAKE_CARDANO_NETWORK ? false : undefined,
        }),
      );

      updateFarmingToken();
    } catch (error) {
      console.error('[SINGLE SIDE DEPOSIT] deposit error : ', error);
      addNotification(
        getDepositNotificationOptions({
          id,
          status: 'error',
          portfolioName,
          step: isCrossChain ? { ...step } : undefined,
        }),
      );

      throw error;
    }
  };

  const updateForm = async () => {
    if (canDoUpdateForm(singleSideForm as SingleSideDepositForm)) {
      await Promise.all([checkAllowance(), estimateDeposit()]);
    }
  };

  const checkChangesAndUpdateFrom = async () => {
    resetEstimateDeposit();
    validate();
    await updateForm();
  };

  // Validate and update form when changes (input amount, balance)
  watch(
    [() => singleSideForm.input.amount, () => singleSideForm.balance],
    async ([newAmount, newBalance], [oldAmount, oldBalance]) => {
      if (
        newAmount &&
        newBalance &&
        oldAmount &&
        oldBalance &&
        newAmount === oldAmount &&
        BigNumber(newBalance).isEqualTo(oldBalance)
      ) {
        return;
      }
      await checkChangesAndUpdateFrom();
    },
  );

  // Amount entered then need update estimate by interval
  watch(
    () => singleSideForm.input.amount,
    async amount => {
      if (!amount && updateFormIntervalId.value) {
        clearUpdateFormInterval(updateFormIntervalId);
      }

      if (amount && !updateFormIntervalId.value) {
        updateFormIntervalId.value = setInterval(checkChangesAndUpdateFrom, UPDATE_INTERVAL);
      }
    },
  );
  onUnmounted(() => {
    if (updateFormIntervalId.value) {
      clearUpdateFormInterval(updateFormIntervalId);
    }
    resetWSCBridge();
  });

  // Validate after estimate
  watch(
    () => singleSideForm.lossPercent,
    () => {
      validate();
    },
  );

  // Wallet
  watch(
    () => walletState.isInjected,
    isWalletConnected => {
      singleSideForm.isWalletConnected = isWalletConnected;
    },
    { immediate: true },
  );

  // Update balance
  watch(
    () => singleSideForm.input.token,
    token => {
      singleSideForm.balance = token?.balance ?? null;
      singleSideForm.isBalanceLoading = !singleSideForm.balance;
    },
  );

  // Update loss and estimate in USD
  watch([() => singleSideForm.estimatedDepositInLP, portfolio], ([estimatedLP, portfolio]) => {
    if (!portfolio) return;
    if (!singleSideForm.input.token) return;
    if (!singleSideForm.input.amount) return;
    if (!depositPrice.value) return;
    if (!lpTokenPriceBase.value) return;
    if (!baseTokenPriceInUSD.value) return;
    if (estimatedLP.isZero()) return;

    const baseToken = portfolio.baseToken;
    const portfolioToken = singleSideForm.input.token.portfolioToken;
    // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.lpToken.decimals
    const estimatedLPInWei = toWei(estimatedLP.toString(), portfolio.lpToken.decimals);
    const enteredAmountInTokenWei = toWei(singleSideForm.input.amount, portfolioToken.decimals);

    singleSideForm.estimatedDepositInUSD = calculateEstimatedDepositInUSD(estimatedLPInWei, {
      baseToken,
      lpTokenPriceBase: lpTokenPriceBase.value,
      baseTokenPriceInUSD: baseTokenPriceInUSD.value,
      lpToken: portfolio.lpToken,
    });

    singleSideForm.lossInUSD = calculateLossInUSD(enteredAmountInTokenWei, estimatedLPInWei, {
      baseToken,
      depositPrice: depositPrice.value,
      lpTokenPriceBase: lpTokenPriceBase.value,
      baseTokenPriceInUSD: baseTokenPriceInUSD.value,
      lpToken: portfolio.lpToken,
    });

    singleSideForm.lossPercent = calculateLossPercent(enteredAmountInTokenWei, estimatedLPInWei, {
      depositPrice: depositPrice.value,
      lpTokenPriceBase: lpTokenPriceBase.value,
      lpToken: portfolio.lpToken,
    });
  });

  // Set data for bridge when changed input
  watch(
    () => singleSideForm.input.amount,
    async amountInput => {
      if (!ENABLE_FAKE_CARDANO_NETWORK) return;

      if (!amountInput) {
        resetWSCBridge();
        return;
      }
      // Wrap bridge
      setIsEVMFromCardano(false);
      setHasBridgeFromMilkomeda(false);
      setBridgeTokensFromCardano([
        { amount: amountInput, token: singleSideForm.input.token!.portfolioToken },
      ]);
    },
  );

  return {
    setFarm,
    singleSideForm,
    $reset,
    setToken,
    setAmount,
    setMax,
    setAllowance,
    estimateDeposit,
    doDeposit,
  };
};

async function checkERC20Allowance(
  address: string,
  account: string,
): Promise<ethers.providers.TransactionResponse> {
  console.log('[SINGLE SIDE DEPOSIT] checkERC20Allowance : ', address);
  const tokenContract = getErc20Contract(address, getInstance()?.web3?.getSigner());
  console.log('[SINGLE SIDE DEPOSIT] tokenContract : ', tokenContract);
  return await tokenContract.allowance(account, getLiquidityDelegateAddress());
}

async function approve(
  address: string,
  amount: string,
): Promise<ethers.providers.TransactionResponse> {
  console.log('[SINGLE SIDE DEPOSIT] approve : ', address);
  const tokenContract = getErc20Contract(address, getInstance()?.web3?.getSigner());
  console.log('[SINGLE SIDE DEPOSIT] tokenContract : ', tokenContract);
  return await transactionWithEstimatedGas(tokenContract, 'approve', [
    getLiquidityDelegateAddress(),
    amount,
  ]);
}

function canDoUpdateForm(singleSideForm: SingleSideDepositForm): boolean {
  const errorNames = Object.keys(singleSideForm.errors);

  const canDoUpdate =
    !errorNames.length ||
    (errorNames.length === 1 &&
      (!!singleSideForm.errors['EXCEEDED_NO_FEE_LIMIT'] ||
        !!singleSideForm.errors['INSUFFICIENTLY_BALANCE'])) ||
    (errorNames.length === 2 &&
      !!singleSideForm.errors['EXCEEDED_NO_FEE_LIMIT'] &&
      !!singleSideForm.errors['INSUFFICIENTLY_BALANCE']);

  console.groupCollapsed('[SINGLE SIDE DEPOSIT] : canDoUpdateForm', canDoUpdate);
  console.log('ERRORS: ', singleSideForm.errors);
  console.groupEnd();

  return canDoUpdate;
}

function calculateEstimatedDepositInUSD(
  estimatedLPInWei: BigNumber,
  {
    baseToken,
    lpTokenPriceBase,
    baseTokenPriceInUSD,
    lpToken,
  }: {
    baseToken: Token;
    lpTokenPriceBase: BigNumber;
    baseTokenPriceInUSD: BigNumber;
    lpToken: Token;
  },
) {
  // LP WEI * ( BASE WEI / LP WEI ) => BASE WEI
  const estimatedLPInUSD = estimatedLPInWei
    .multipliedBy(lpTokenPriceBase)
    .multipliedBy(baseTokenPriceInUSD)
    .shiftedBy(-baseToken.decimals);

  console.groupCollapsed(
    '[SINGLE SIDE DEPOSIT] Calc estimated LP in USD : ',
    estimatedLPInUSD.toString(),
  );
  console.log('lpTokenPriceBase [raw: base wei / LP wei] : ', lpTokenPriceBase.toString());
  console.log('baseTokenPriceInUSD : ', baseTokenPriceInUSD.toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log('estimatedLP : ', estimatedLPInWei.shiftedBy(-lpToken.decimals).toString());
  console.groupEnd();

  return estimatedLPInUSD;
}

// LOSS in $ = (token_amount * deposit_price / LP_token_price - response_LP_amount) * LP_token_price * base_token_price_in_USD
function calculateLossInUSD(
  enteredAmountInTokenWei: BigNumber,
  estimatedLPInWei: BigNumber,
  {
    baseToken,
    depositPrice,
    lpTokenPriceBase,
    baseTokenPriceInUSD,
    lpToken,
  }: {
    baseToken: Token;
    depositPrice: BigNumber;
    lpTokenPriceBase: BigNumber;
    baseTokenPriceInUSD: BigNumber;
    lpToken: Token;
  },
) {
  // TOKEN WEI * (BASE WEI / TOKEN WEI) / (BASE WEI / LP WEI) => LP WEI
  const enteredAmountInLPWei = enteredAmountInTokenWei
    .multipliedBy(depositPrice)
    .div(lpTokenPriceBase);

  const lossInLPWei = enteredAmountInLPWei.minus(estimatedLPInWei);
  // LP WEI * (BASE WEI / LP WEI) => BASE WEI
  const lossInUSD = lossInLPWei
    .multipliedBy(lpTokenPriceBase)
    .multipliedBy(baseTokenPriceInUSD)
    .shiftedBy(-baseToken.decimals);

  console.groupCollapsed('[SINGLE SIDE DEPOSIT] Calc loss in USD : ', lossInUSD.toString());
  console.log('depositPrice [raw: base wei / token wei] : ', depositPrice.toString());
  console.log('lpTokenPriceBase [raw: base wei / LP wei] : ', lpTokenPriceBase.toString());
  console.log('baseTokenPriceInUSD : ', baseTokenPriceInUSD.toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log('estimatedLP : ', estimatedLPInWei.shiftedBy(-lpToken.decimals).toString());
  console.log('entered amount in token wei : ', enteredAmountInTokenWei.toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log(
    'entered amount in LP : ',
    enteredAmountInLPWei.shiftedBy(-lpToken.decimals).toString(),
  );
  console.groupEnd();

  return lossInUSD;
}

// LOSS in % = 1 - response_LP_amount / (token_amount * deposit_price / LP_token_price)
function calculateLossPercent(
  enteredAmountInTokenWei: BigNumber,
  estimatedLPInWei: BigNumber,
  {
    depositPrice,
    lpTokenPriceBase,
    lpToken,
  }: { depositPrice: BigNumber; lpTokenPriceBase: BigNumber; lpToken: Token },
) {
  // TOKEN WEI * (BASE WEI / TOKEN WEI) / (BASE WEI / LP WEI) => LP WEI
  const enteredAmountInLPWei = enteredAmountInTokenWei
    .multipliedBy(depositPrice)
    .div(lpTokenPriceBase);

  const lossPercent = max(
    BIG_ONE.minus(estimatedLPInWei.div(enteredAmountInLPWei)).multipliedBy(100),
    0,
  );
  console.groupCollapsed('[SINGLE SIDE DEPOSIT] Calc loss percent : ', lossPercent.toString());
  console.log('depositPrice [raw: base wei / token wei] : ', depositPrice.toString());
  console.log('lpTokenPriceBase [raw: base wei / LP wei] : ', lpTokenPriceBase.toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log('estimatedLP : ', estimatedLPInWei.shiftedBy(-lpToken.decimals).toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log(
    'entered amount in LP : ',
    enteredAmountInLPWei.shiftedBy(-lpToken.decimals).toString(),
  );
  console.groupEnd();

  return lossPercent;
}

function clearUpdateFormInterval(intervalIdRef: Ref<NodeJS.Timer | undefined>) {
  intervalIdRef.value ? clearInterval(intervalIdRef.value) : undefined;
  intervalIdRef.value = undefined;
}
