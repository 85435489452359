import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { Ref, computed, onUnmounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { fromWei, getScanLink, toWei } from '@/sdk/utils';
import { safeParseUnits } from '@/helpers/utils';
import { BIG_ONE, BIG_ZERO, max, min } from '@/utils/bigNumber';
import { getErc20Contract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { getLiquidityDelegateAddress } from '@/helpers/address.helper';
import {
  DelegatedWithdrawParamsWithSignature,
  useSingleSideWithdrawTransaction,
} from '@/composables/single-side/useSingleSideWithdrawTransaction';
import { SingleSideWithdrawForm } from '@/store/modules/single-side/models/single-side-withdraw-form';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useSingleSideMilkomedaWSCBridge } from '@/store/modules/single-side/useSingleSideMilkomedaWSCBridge';
import { useSingleSideMilkomedaWSCUnwrapBridge } from '@/store/modules/single-side/useSingleSideMilkomedaWSCUnwrapBridge';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import { usePortfolioEstimateWithdraw } from '@/composables/portfolio/usePortfolioEstimateWithdraw';
import {
  INotification,
  INotificationStep,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { SingleSideToken } from '@/views/pages/liquidity/single-side/models/single-side-token';
import { Portfolio } from '@/sdk/entities/portfolio';
import { PortfolioFarm } from '@/sdk/entities/portfolioFarm';
import { Token } from '@/sdk/entities/token';
import { LOSS_LIMIT } from './constants/LOSS_LIMIT';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import { useFarmingTokens } from '../tokens/useFarmingTokens';
import { EMPTY_SIGNATURE } from '@/composables/useWalletSignature';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';

export const useSingleSideWithdraw = () => {
  const { t } = useI18n();
  const { state } = useStore();
  const { walletState } = useEVMWallet();
  const { getTokenBySymbolAndChainId } = useTokens();
  const { updateFarmingToken } = useFarmingTokens();
  const { fetchExactTokenWeiAmountsAfterWithdraw } = usePortfolioEstimateWithdraw();
  const {
    getDelegatedWithdrawParams,
    signDelegateWithdrawParams,
    getDelegateWithdrawMethodCallArgs,
    createDelegateWithdrawTransaction,
  } = useSingleSideWithdrawTransaction();
  const {
    milkomedaWSCBridgeState,
    setIsEVMFromCardano,
    setBridgeTokensFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetWSCBridge,
  } = useSingleSideMilkomedaWSCBridge();
  const {
    setIsEVMFromMilkomeda,
    setBridgeTokensFromMilkomeda,
    $reset: resetWSCUnwrapBridge,
  } = useSingleSideMilkomedaWSCUnwrapBridge();

  const farm = ref<PortfolioFarm | null>();
  const portfolio = ref<Portfolio | null>();
  const updateFormIntervalId = ref<NodeJS.Timer>();

  const singleSideForm = reactive<SingleSideWithdrawForm>({
    isWalletConnected: false,
    isBalanceLoading: true,
    balance: null, // in token
    balanceLPInWallet: null,
    balanceLPInFarm: null,
    LPToken: null,
    amountLP: null, // you spend LP
    amountLPInUSD: null,
    amountLPFromWallet: null, // you spend LP from wallet
    amountLPFromFarm: null, // you spend LP from farm
    input: {
      token: null,
      amount: null,
    },
    isEstimateWithdrawRequestInProgress: false,
    estimatedWithdrawInToken: BIG_ZERO,
    estimatedWithdrawInUSD: BIG_ZERO,
    lossInUSD: BIG_ZERO,
    lossPercent: BIG_ZERO,
    isAllowanceRequestInProgress: false,
    hasAllowance: undefined,
    errors: {},
  });

  const setLPToken = () => {
    if (!portfolio.value) return;
    singleSideForm.LPToken = portfolio.value.lpToken;
  };

  const setFarm = (newFarm: PortfolioFarm) => {
    farm.value = newFarm;
    portfolio.value = newFarm.portfolio;
    setLPToken();
  };

  const cardanoADAToken = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_CARDANO_CHAIN_ID);
  });

  // BASE WEI / TOKEN WEI
  const withdrawPrice = computed(() => {
    if (!singleSideForm.input.token) return undefined;
    if (!portfolio.value) return undefined;

    const portfolioToken = singleSideForm.input.token.portfolioToken;
    const tokenInfo = portfolio.value.tokensInfoByAddr[portfolioToken.address];

    return tokenInfo
      .getWithdrawPrice()
      .shiftedBy(portfolio.value.baseToken.decimals - portfolioToken.decimals);
  });

  // BASE WEI / LP WEI
  const lpTokenPriceBase = computed(() => {
    if (!portfolio.value) return undefined;

    // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.value.lpToken.decimals
    return portfolio.value.lpTokenPriceBase.shiftedBy(
      portfolio.value.baseToken.decimals - portfolio.value.lpToken.decimals,
    );
  });

  // USD / BASE
  const baseTokenPriceInUSD = computed(() => {
    if (!portfolio.value) return undefined;

    return portfolio.value.priceInUSD;
  });

  const { addNotification } = useNotifications();

  const getApproveNotificationOptions = (
    status: NotificationStatus,
    explorer?: string,
  ): INotification => {
    const lpToken = singleSideForm.LPToken;
    return {
      id: `approve_${lpToken?.symbol}`,
      status: status,
      content: t(`singleSide.notificationContent.approve.${status}`, {
        token: t('lpTokens'),
      }),
      life: status !== 'inProgress',
      explorerLink: explorer,
    };
  };

  const getWithdrawNotificationOptions = (options: {
    status: NotificationStatus;
    id: string;
    portfolioName: string;
    step?: INotificationStep;
  }): INotification => {
    return {
      ...options,
      content: t(`singleSide.notificationContent.withdraw.${options.status}`, {
        portfolioName: options.portfolioName,
      }),
      explorerLink: options.id
        ? getScanLink(options.id, 'transaction', options.step?.chainId)
        : undefined,
    };
  };

  const resetEstimateWithdraw = () => {
    singleSideForm.estimatedWithdrawInToken = BIG_ZERO;
    singleSideForm.estimatedWithdrawInUSD = BIG_ZERO;
    singleSideForm.lossInUSD = BIG_ZERO;
    singleSideForm.lossPercent = BIG_ZERO;
  };

  const $reset = (): void => {
    resetEstimateWithdraw();
    singleSideForm.input.amount = null;
    singleSideForm.hasAllowance = undefined;
    singleSideForm.isAllowanceRequestInProgress = false;
    singleSideForm.errors = {};
  };

  const validate = () => {
    singleSideForm.errors = {};
    const { balance } = singleSideForm;
    const { token, amount } = singleSideForm.input;

    if (!token) singleSideForm.errors['PORTFOLIO_REQUIRED'] = true;
    if (!token) singleSideForm.errors['TOKEN_REQUIRED'] = true;
    if (!amount) singleSideForm.errors['AMOUNT_REQUIRED'] = true;
    if (amount && +amount === 0) singleSideForm.errors['AMOUNT_IS_ZERO'] = true;
    if (!balance) singleSideForm.errors['BALANCE_REQUIRED'] = true;
    if (amount && balance && BigNumber(amount).gt(balance)) {
      singleSideForm.errors['INSUFFICIENTLY_BALANCE'] = true;
    }
    if (
      ENABLE_FAKE_CARDANO_NETWORK &&
      amount &&
      balance &&
      milkomedaWSCBridgeState.needBridge &&
      !milkomedaWSCBridgeState.isValidCardanoBalance
    ) {
      singleSideForm.errors['INSUFFICIENTLY_BALANCE'] = true;
    }
    if (
      amount &&
      singleSideForm.input.token &&
      BigNumber(amount).gt(singleSideForm.input.token.noFeeLimit)
    ) {
      singleSideForm.errors['EXCEEDED_NO_FEE_LIMIT'] = true;
    }
    if (singleSideForm.lossPercent.gt(LOSS_LIMIT)) {
      singleSideForm.errors['HUGE_LOSS'] = true;
    }
  };

  const setToken = (token: SingleSideToken): void => {
    singleSideForm.input.token = token;
  };

  const setAmount = (amount: string | null): void => {
    const isAmountIsNotEmpty = !amount || amount.length > 0 || +amount !== 0;
    if (isAmountIsNotEmpty) {
      singleSideForm.input.amount = amount;
    } else {
      singleSideForm.input.amount = null;
    }
  };

  const checkLPInWalletAllowance = async () => {
    console.log('[SINGLE SIDE WITHDRAW] checkLPInWalletAllowance : ', singleSideForm.LPToken);

    if (!singleSideForm.LPToken) return false;
    if (!singleSideForm.amountLP) return false;
    if (!singleSideForm.amountLPFromWallet) return false;
    if (!singleSideForm.amountLPFromFarm) return false;
    if (!singleSideForm.balanceLPInWallet) return false;

    const lpToken = singleSideForm.LPToken;

    try {
      const allowanceResult = await checkERC20Allowance(lpToken.address, walletState.account ?? '');
      const allowanceAmountInLPWei = BigNumber(allowanceResult.toString());
      // amount LP which will withdraw from wallet
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
      const amountFromWalletInLPWei = toWei(
        singleSideForm.amountLPFromWallet.toString(),
        lpToken.decimals,
      );
      // amount LP which will withdraw from farm
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
      const amountFromFarmInLPWei = toWei(
        singleSideForm.amountLPFromFarm.toString(),
        lpToken.decimals,
      );
      // balance LP from wallet
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
      const balanceLPInWalletInLPWei = toWei(
        singleSideForm.balanceLPInWallet.toString(),
        lpToken.decimals,
      );

      const maxAllowanceInLPWei = (
        amountFromWalletInLPWei.gte(balanceLPInWalletInLPWei)
          ? balanceLPInWalletInLPWei
          : amountFromWalletInLPWei
      ).decimalPlaces(0, BigNumber.ROUND_DOWN);

      console.groupCollapsed(
        '[SINGLE SIDE WITHDRAW] checkAllowance : ',
        allowanceAmountInLPWei.gte(maxAllowanceInLPWei),
      );
      console.log('allowanceResult : ', allowanceResult.toString());
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
      console.log(
        'allowance amount LP [LP] : ',
        fromWei(allowanceAmountInLPWei, lpToken.decimals).toString(),
      );
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
      console.log(
        `balance LP on wallet [LP] : `,
        fromWei(balanceLPInWalletInLPWei, lpToken.decimals).toString(),
      );
      console.log(`balance LP on farm [LP] : `, singleSideForm.balanceLPInFarm?.toString() ?? 0);
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
      console.log(
        'max allowance amount LP [LP] : ',
        fromWei(maxAllowanceInLPWei, lpToken.decimals).toString(),
      );
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
      console.log(
        `amount LP from wallet [LP] : `,
        fromWei(amountFromWalletInLPWei, lpToken.decimals).toString(),
      );
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
      console.log(
        `amount LP from farm [LP] : `,
        fromWei(amountFromFarmInLPWei, lpToken.decimals).toString(),
      );
      console.log(`entered amount LP [LP] : `, singleSideForm.amountLP.toString());
      console.groupEnd();

      singleSideForm.hasAllowance = allowanceAmountInLPWei.gte(maxAllowanceInLPWei);
    } catch (e) {
      singleSideForm.hasAllowance = false;
      throw Error(e);
    }
  };

  const setAllowance = async () => {
    console.log('[SINGLE SIDE WITHDRAW] : setAllowance');

    if (!singleSideForm.LPToken) return false;
    if (!singleSideForm.amountLPFromWallet) return false;
    if (!singleSideForm.balanceLPInWallet) return false;

    singleSideForm.isAllowanceRequestInProgress = true;
    const lpToken = singleSideForm.LPToken;
    try {
      // amount LP which will withdraw from wallet
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> singleSideForm.LPToken.decimals
      const amountInLPWei = safeParseUnits(
        singleSideForm.amountLPFromWallet.toString(),
        singleSideForm.LPToken.decimals,
      ).toString();
      // balance LP from wallet
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> singleSideForm.LPToken.decimals
      const balanceInLPWei = safeParseUnits(
        singleSideForm.balanceLPInWallet.toString(),
        singleSideForm.LPToken.decimals,
      ).toString();
      const maxAllowanceInLPWei = BigNumber(amountInLPWei).gte(balanceInLPWei)
        ? balanceInLPWei
        : amountInLPWei;

      const result = await approve(lpToken.address, maxAllowanceInLPWei);

      const explorerLink = getScanLink(result.hash, 'transaction');
      addNotification(getApproveNotificationOptions('inProgress', explorerLink));
      await result.wait();
      addNotification(getApproveNotificationOptions('success', explorerLink));

      await checkLPInWalletAllowance();
    } catch (error) {
      addNotification(getApproveNotificationOptions('error'));

      throw Error(error);
    } finally {
      singleSideForm.isAllowanceRequestInProgress = false;
    }
  };

  const setMax = () => {
    if (!singleSideForm.balance) return;
    if (!singleSideForm.input.token) return;

    const maxFractionDigits = singleSideForm.input.token.portfolioToken.decimals;
    singleSideForm.input.amount =
      singleSideForm.balance.toFixed(maxFractionDigits, BigNumber.ROUND_DOWN) ?? '';
  };

  const estimateWithdraw = async () => {
    console.log('[SINGLE SIDE WITHDRAW] : estimateWithdraw');

    if (!singleSideForm.input.token) return false;
    if (!singleSideForm.input.amount) return false;
    if (!singleSideForm.amountLP) return false;
    if (!singleSideForm.LPToken) return false;
    if (singleSideForm.isEstimateWithdrawRequestInProgress) return false;

    singleSideForm.isEstimateWithdrawRequestInProgress = true;
    try {
      const token = singleSideForm.input.token.portfolioToken;
      const withdrawTokens = [
        {
          token: token,
          // NOTE: Changed `LP_TOKEN_DECIMALS` -> singleSideForm.LPToken.decimals
          lpAmountInWei: toWei(singleSideForm.amountLP.toString(), singleSideForm.LPToken.decimals),
        },
      ];
      const tokenAmountsWithdrawInWei = await fetchExactTokenWeiAmountsAfterWithdraw(
        singleSideForm.input.token.portfolio.address,
        withdrawTokens,
      );

      singleSideForm.estimatedWithdrawInToken = fromWei(
        tokenAmountsWithdrawInWei[0],
        token.decimals,
      );
    } catch (err) {
      console.error('[SINGLE SIDE WITHDRAW] Estimate deposit error : ', err);
      singleSideForm.errors['PRICE_IMPACT'] = true;
    } finally {
      singleSideForm.isEstimateWithdrawRequestInProgress = false;
    }
  };

  const withdrawByLiquidityDelegate = async (
    withdrawTokens: {
      token: Pick<Token, 'address' | 'decimals'>;
      lpInWeiAmount: BigNumber;
      amountOutInWei: BigNumber;
    }[],
    portfolio: Portfolio,
    amountLPFromFarm: BigNumber,
    farm: PortfolioFarm,
  ): Promise<ethers.providers.TransactionResponse> => {
    // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.lpToken.decimals
    const delegatedWithdrawParams = await getDelegatedWithdrawParams({
      lpAmountFromFarmInWei: toWei(amountLPFromFarm.toString(), portfolio.lpToken.decimals).toFixed(
        0,
        BigNumber.ROUND_DOWN,
      ),
      portfolioAddress: portfolio.contractAddress,
      farmAddress: farm.farm,
    });

    const delegatedWithdrawParamsWithSignature: DelegatedWithdrawParamsWithSignature =
      amountLPFromFarm.isZero()
        ? {
            ...delegatedWithdrawParams,
            signature: EMPTY_SIGNATURE,
          }
        : await signDelegateWithdrawParams(delegatedWithdrawParams);

    const callArgs = getDelegateWithdrawMethodCallArgs({
      withdrawTokens,
      delegatedWithdrawParamsWithSignature,
    });

    const transactionResponse = await createDelegateWithdrawTransaction(callArgs);

    return transactionResponse;
  };

  const doWithdraw = async () => {
    console.log('[SINGLE SIDE WITHDRAW] withdraw');

    if (!singleSideForm.input.token) {
      console.warn('[SINGLE SIDE WITHDRAW] Can not do withdraw, cause no token.');
      return false;
    }
    if (!singleSideForm.amountLP) {
      console.warn('[SINGLE SIDE WITHDRAW] Can not do withdraw, cause amount LP is undefined.');
      return false;
    }
    if (!singleSideForm.LPToken) {
      console.warn(
        '[SINGLE SIDE WITHDRAW] Can not do withdraw, cause amount LP token is undefined.',
      );
      return false;
    }
    if (!singleSideForm.amountLPFromFarm) {
      console.warn(
        '[SINGLE SIDE WITHDRAW] Can not do withdraw, cause amount LP from farm is undefined.',
      );
      return false;
    }
    if (!portfolio.value) {
      console.warn('[SINGLE SIDE WITHDRAW] Can not do withdraw, cause portfolio unknown.');
      return false;
    }
    if (!farm.value) {
      console.warn('[SINGLE SIDE WITHDRAW] Can not do withdraw, cause farm unknown.');
      return false;
    }

    const portfolioName = portfolio.value.name;

    let id = '';
    // NOTE: will need for cross chain.
    const step: INotificationStep = {
      current: 1,
      total: 2,
      chainId: singleSideForm.input.token?.portfolioToken.chainId,
    };
    const isCrossChain = singleSideForm.input.token.isCrossChain;

    const portfolioToken = singleSideForm.input.token.portfolioToken;
    const withdrawTokens = [
      {
        token: portfolioToken,
        // NOTE: Changed `LP_TOKEN_DECIMALS` -> singleSideForm.LPToken.decimals
        lpInWeiAmount: toWei(singleSideForm.amountLP.toString(), singleSideForm.LPToken.decimals),
        amountOutInWei: toWei(
          singleSideForm.estimatedWithdrawInToken.toString(),
          portfolioToken.decimals,
        ),
      },
    ];

    try {
      const transactionResponse = await withdrawByLiquidityDelegate(
        withdrawTokens,
        portfolio.value,
        BigNumber(singleSideForm.amountLPFromFarm),
        farm.value,
      );

      id = transactionResponse.hash;
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'inProgress',
          portfolioName,
          step: isCrossChain ? { ...step } : undefined,
        }),
      );

      $reset();

      await transactionResponse.wait();

      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'success',
          portfolioName,
          step: isCrossChain ? { ...step } : undefined,
        }),
      );

      updateFarmingToken();
    } catch (error) {
      console.error('[SINGLE SIDE WITHDRAW] withdraw error : ', error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }

      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'error',
          portfolioName,
          step: isCrossChain ? { ...step } : undefined,
        }),
      );

      throw Error(error);
    }
  };

  const updateForm = async () => {
    if (canDoUpdateForm(singleSideForm as SingleSideWithdrawForm)) {
      await Promise.all([checkLPInWalletAllowance(), estimateWithdraw()]);
    }
  };

  const checkChangesAndUpdateFrom = async () => {
    resetEstimateWithdraw();
    validate();
    await updateForm();
  };

  // Wallet
  watch(
    () => walletState.isInjected,
    isWalletConnected => {
      singleSideForm.isWalletConnected = isWalletConnected;
    },
    { immediate: true },
  );

  // Watch LP balance in wallet
  watch(
    () => portfolio.value?.balanceOfWallet,
    lpBalanceInWallet => {
      if (!lpBalanceInWallet) return;

      // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.value.lpToken.decimals
      singleSideForm.balanceLPInWallet = fromWei(
        lpBalanceInWallet,
        portfolio.value?.lpToken.decimals,
      );
      console.log(
        `[SINGLE SIDE WITHDRAW] [${portfolio.value?.name}] lp balance in wallet : `,
        singleSideForm.balanceLPInWallet.toString(),
      );
    },
  );

  // Watch LP balance in farm
  watch(
    () => state.farming.lp[singleSideForm.LPToken?.address ?? '']?.deposited,
    lpBalanceInFarm => {
      if (!lpBalanceInFarm) return;

      // NOTE: Changed `LP_TOKEN_DECIMALS` -> singleSideForm.LPToken?.decimals
      singleSideForm.balanceLPInFarm = fromWei(lpBalanceInFarm, singleSideForm.LPToken?.decimals);
      console.log(
        `[SINGLE SIDE WITHDRAW] [${portfolio.value?.name}] lp balance in farm : `,
        singleSideForm.balanceLPInFarm.toString(),
      );
    },
  );

  /**
   * Calc and update available balance.
   *
   * Convert LP to token.
   * LP_total = LP_from_wallet + LP_from_farm
   * LP_total * lp_token_price / withdraw_price = available balance in token
   */
  watch(
    [
      () => singleSideForm.balanceLPInWallet,
      () => singleSideForm.balanceLPInFarm,
      withdrawPrice,
      lpTokenPriceBase,
    ],
    ([balanceLPInWallet, balanceLPInFarm]) => {
      if (!balanceLPInWallet) return;
      if (!balanceLPInFarm) return;
      if (!singleSideForm.input.token) return;
      if (!portfolio.value) return;
      if (!withdrawPrice.value) return;
      if (!lpTokenPriceBase.value) return;

      singleSideForm.balance = calculateAvailableBalanceInToken(
        BigNumber(balanceLPInWallet),
        BigNumber(balanceLPInFarm),
        {
          token: singleSideForm.input.token.portfolioToken,
          withdrawPrice: withdrawPrice.value,
          lpTokenPriceBase: lpTokenPriceBase.value,
          lpToken: portfolio.value.lpToken,
        },
      );
      singleSideForm.isBalanceLoading = false;
    },
    { immediate: true },
  );

  // Update entered amount in LP
  watch(
    [() => singleSideForm.input.amount, withdrawPrice, lpTokenPriceBase],
    () => {
      if (!singleSideForm.input.token) return;
      if (!singleSideForm.input.amount) return;
      if (!singleSideForm.LPToken) return;
      if (!withdrawPrice.value) return;
      if (!lpTokenPriceBase.value) return;

      const amountInToken = singleSideForm.input.amount;
      // TOKEN WEI * (BASE WEI / TOKEN WEI) => BASE WEI
      const amountInBaseWei = toWei(
        amountInToken,
        singleSideForm.input.token.portfolioToken.decimals,
      ).multipliedBy(withdrawPrice.value);

      // BASE WEI / (BASE WEI / LP WEI) => LP WEI
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> singleSideForm.LPToken.decimals
      singleSideForm.amountLP = fromWei(
        amountInBaseWei.div(lpTokenPriceBase.value),
        singleSideForm.LPToken.decimals,
      );
    },
    { immediate: true },
  );

  // Update amount LP in USD
  watch(
    [() => singleSideForm.amountLP, lpTokenPriceBase, baseTokenPriceInUSD],
    () => {
      if (!singleSideForm.amountLP) return;
      if (!lpTokenPriceBase.value) return;
      if (!baseTokenPriceInUSD.value) return;
      if (!portfolio.value) return;

      const baseToken = portfolio.value.baseToken;
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.value.lpToken.decimals
      const amountLPInWei = toWei(
        singleSideForm.amountLP.toString(),
        portfolio.value.lpToken.decimals,
      );

      // LP WEI * (BASE WEI / LP WEI) => BASE WEI
      singleSideForm.amountLPInUSD = amountLPInWei
        .multipliedBy(lpTokenPriceBase.value)
        .multipliedBy(baseTokenPriceInUSD.value)
        .shiftedBy(-baseToken.decimals);
    },
    { immediate: true },
  );

  /**
   * Update spend LP from wallet
   *
   * from_wallet = min (entered_amount_in_LP , LP_wallet_balance)
   */
  watch(
    [() => singleSideForm.amountLP, () => singleSideForm.balanceLPInWallet],
    ([amountInLP, balanceLPInWallet]) => {
      if (!amountInLP) return;
      if (!balanceLPInWallet) return;

      singleSideForm.amountLPFromWallet = min(BigNumber(amountInLP), BigNumber(balanceLPInWallet));
    },
    { immediate: true },
  );

  /**
   * Update spend LP from farm
   *
   * from_farm = entered_amount_in_LP - from_wallet
   */
  watch(
    [() => singleSideForm.amountLP, () => singleSideForm.amountLPFromWallet],
    ([amountInLP, amountLPFromWallet]) => {
      if (!amountInLP) return;
      if (!amountLPFromWallet) return;

      singleSideForm.amountLPFromFarm = amountInLP.minus(amountLPFromWallet);
    },
    { immediate: true },
  );

  // Validate and update form when changes (input amount, balance)
  watch(
    [() => singleSideForm.amountLP, () => singleSideForm.balance],
    async ([newAmountLP, newBalance], [oldAmountLP, oldBalance]) => {
      if (
        newAmountLP &&
        newBalance &&
        oldAmountLP &&
        oldBalance &&
        BigNumber(newAmountLP).isEqualTo(oldAmountLP) &&
        BigNumber(newBalance).isEqualTo(oldBalance)
      ) {
        return;
      }
      await checkChangesAndUpdateFrom();
    },
  );

  // Amount entered then need update estimate by interval
  watch(
    () => singleSideForm.input.amount,
    async amount => {
      if (!amount && updateFormIntervalId.value) {
        clearUpdateFormInterval(updateFormIntervalId);
      }

      if (amount && !updateFormIntervalId.value) {
        updateFormIntervalId.value = setInterval(checkChangesAndUpdateFrom, UPDATE_INTERVAL);
      }
    },
  );
  onUnmounted(() => {
    if (updateFormIntervalId.value) {
      clearUpdateFormInterval(updateFormIntervalId);
    }
  });

  // Validate after estimate
  watch(
    () => singleSideForm.lossPercent,
    () => {
      validate();
    },
  );

  // Update loss and estimate in USD
  watch(
    [
      () => singleSideForm.estimatedWithdrawInToken,
      withdrawPrice,
      lpTokenPriceBase,
      baseTokenPriceInUSD,
    ],
    ([estimatedWithdrawInToken]) => {
      if (!portfolio.value) return;
      if (!singleSideForm.input.token) return;
      if (!singleSideForm.amountLP) return;
      if (!withdrawPrice.value) return;
      if (!lpTokenPriceBase.value) return;
      if (!baseTokenPriceInUSD.value) return;
      if (estimatedWithdrawInToken.isZero()) return;

      const baseToken = portfolio.value.baseToken;
      const portfolioToken = singleSideForm.input.token.portfolioToken;
      // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.value.lpToken.decimals
      const enteredAmountInLPWei = toWei(
        singleSideForm.amountLP.toString(),
        portfolio.value.lpToken.decimals,
      );
      const estimatedWithdrawInTokenWei = toWei(
        estimatedWithdrawInToken.toString(),
        portfolioToken.decimals,
      );

      singleSideForm.estimatedWithdrawInUSD = calculateEstimatedWithdrawInUSD(
        estimatedWithdrawInTokenWei,
        {
          baseToken,
          withdrawPrice: withdrawPrice.value,
          baseTokenPriceInUSD: baseTokenPriceInUSD.value,
        },
      );

      singleSideForm.lossInUSD = calculateLossInUSD(
        enteredAmountInLPWei,
        estimatedWithdrawInTokenWei,
        {
          baseToken,
          withdrawPrice: withdrawPrice.value,
          lpTokenPriceBase: lpTokenPriceBase.value,
          baseTokenPriceInUSD: baseTokenPriceInUSD.value,
        },
      );

      singleSideForm.lossPercent = calculateLossPercent(
        enteredAmountInLPWei,
        estimatedWithdrawInTokenWei,
        {
          withdrawPrice: withdrawPrice.value,
          lpTokenPriceBase: lpTokenPriceBase.value,
        },
      );
    },
  );

  // Set data for revert bridge when changed input
  watch(
    () => singleSideForm.input.amount,
    async amountInput => {
      if (!ENABLE_FAKE_CARDANO_NETWORK) return;

      if (!amountInput) {
        resetWSCBridge();
        resetWSCUnwrapBridge();
        return;
      }
      // Wrap bridge
      setIsEVMFromCardano(false);
      setHasBridgeFromMilkomeda(true);
      setBridgeTokensFromCardano([{ amount: '0', token: cardanoADAToken.value }]);
      // Unwrap bridge
      setIsEVMFromMilkomeda(false);
      setBridgeTokensFromMilkomeda([
        { amount: amountInput, token: singleSideForm.input.token!.portfolioToken },
      ]);
    },
  );

  return {
    setFarm,
    singleSideForm,
    $reset,
    setToken,
    setAmount,
    setMax,
    setAllowance,
    estimateWithdraw,
    doWithdraw,
  };
};

async function checkERC20Allowance(address: string, account: string): Promise<any> {
  const spender = getLiquidityDelegateAddress();
  const tokenContract = getErc20Contract(address, getInstance()?.web3?.getSigner());

  console.groupCollapsed('[SINGLE SIDE WITHDRAW] checkERC20Allowance : ', address);
  console.log('token address : ', address);
  console.log('token contract : ', tokenContract);
  console.log('account : ', account);
  console.log('spender : ', spender);
  console.groupEnd();

  return await tokenContract.allowance(account, spender);
}

async function approve(
  address: string,
  amount: string,
): Promise<ethers.providers.TransactionResponse> {
  const spender = getLiquidityDelegateAddress();
  const tokenContract = getErc20Contract(address, getInstance()?.web3?.getSigner());

  console.groupCollapsed('[SINGLE SIDE WITHDRAW] approve : ', address);
  console.log('token address : ', address);
  console.log('token contract : ', tokenContract);
  console.log('amount : ', amount);
  console.log('spender address : ', spender);
  console.groupEnd();

  return await transactionWithEstimatedGas(tokenContract, 'approve', [spender, amount]);
}

function canDoUpdateForm(singleSideForm: SingleSideWithdrawForm): boolean {
  const errorNames = Object.keys(singleSideForm.errors);

  const canDoUpdate =
    !errorNames.length ||
    (errorNames.length === 1 &&
      (!!singleSideForm.errors['EXCEEDED_NO_FEE_LIMIT'] ||
        !!singleSideForm.errors['INSUFFICIENTLY_BALANCE'])) ||
    (errorNames.length === 2 &&
      !!singleSideForm.errors['EXCEEDED_NO_FEE_LIMIT'] &&
      !!singleSideForm.errors['INSUFFICIENTLY_BALANCE']);

  console.groupCollapsed('[SINGLE SIDE WITHDRAW] : canDoUpdate', canDoUpdate);
  console.log('ERRORS: ', singleSideForm.errors);
  console.groupEnd();

  return canDoUpdate;
}

// LP_total * lp_token_price / withdraw_price = available balance in token
function calculateAvailableBalanceInToken(
  balanceLPInWallet: BigNumber,
  balanceLPInFarm: BigNumber,
  {
    token,
    withdrawPrice,
    lpTokenPriceBase,
    lpToken,
  }: { token: Token; withdrawPrice: BigNumber; lpTokenPriceBase: BigNumber; lpToken: Token },
) {
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  const balanceLPInWalletInWei = toWei(balanceLPInWallet, lpToken.decimals);
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  const balanceLPInFarmInWei = toWei(balanceLPInFarm, lpToken.decimals);
  const availableBalanceLPInWei = balanceLPInWalletInWei.plus(balanceLPInFarmInWei);

  // LP WEI * (BASE WEI / LP WEI) => BASE WEI
  const availableBalanceInBaseWei = availableBalanceLPInWei.multipliedBy(lpTokenPriceBase);
  // BASE WEI / (BASE WEI / TOKEN WEI) => TOKEN WEI
  const availableBalanceInToken = fromWei(
    availableBalanceInBaseWei.div(withdrawPrice),
    token.decimals,
  );

  console.groupCollapsed(
    `[SINGLE SIDE WITHDRAW] Calc available balance in ${token.symbol} : `,
    availableBalanceInToken.toString(),
  );
  console.log('withdrawPrice [raw: base wei / token wei ] : ', withdrawPrice.toString());
  console.log('lpTokenPriceBase [raw: base wei / lp wei ] : ', lpTokenPriceBase.toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log(
    'availableBalanceLP : ',
    availableBalanceLPInWei.shiftedBy(-lpToken.decimals).toString(),
  );
  console.groupEnd();

  return availableBalanceInToken;
}

function calculateEstimatedWithdrawInUSD(
  estimatedWithdrawInTokenWei: BigNumber,
  {
    baseToken,
    withdrawPrice,
    baseTokenPriceInUSD,
  }: {
    baseToken: Token;
    withdrawPrice: BigNumber;
    baseTokenPriceInUSD: BigNumber;
  },
) {
  const estimatedWithdrawInUsd = estimatedWithdrawInTokenWei
    .multipliedBy(withdrawPrice)
    .multipliedBy(baseTokenPriceInUSD)
    .shiftedBy(-baseToken.decimals);

  console.groupCollapsed(
    '[SINGLE SIDE WITHDRAW] Calc estimated token in USD : ',
    estimatedWithdrawInUsd.toString(),
  );
  console.log('withdrawPrice [raw: base wei / token wei] : ', withdrawPrice.toString());
  console.log('baseTokenPriceInUSD : ', baseTokenPriceInUSD.toString());
  console.log('estimated token in token wei : ', estimatedWithdrawInTokenWei.toString());
  console.groupEnd();

  return estimatedWithdrawInUsd;
}

// loss in $ = (LP_token_amount * LP_token_price - response_token_amount * withdraw_price) * base_token_price_in_USD
function calculateLossInUSD(
  enteredAmountInLPWei: BigNumber,
  estimatedTokenInWei: BigNumber,
  {
    baseToken,
    withdrawPrice,
    lpTokenPriceBase,
    baseTokenPriceInUSD,
  }: {
    baseToken: Token;
    withdrawPrice: BigNumber;
    lpTokenPriceBase: BigNumber;
    baseTokenPriceInUSD: BigNumber;
  },
) {
  // LP WEI *  (BASE WEI / LP WEI) => BASE WEI
  const enteredAmountInBaseWei = enteredAmountInLPWei.multipliedBy(lpTokenPriceBase);
  // TOKEN WEI * (BASE WEI / TOKEN WEI) => BASE WEI
  const estimatedTokenInBaseWei = estimatedTokenInWei.multipliedBy(withdrawPrice);

  const lossInBaseWei = max(enteredAmountInBaseWei.minus(estimatedTokenInBaseWei), 0);
  const lossInUSD = lossInBaseWei.multipliedBy(baseTokenPriceInUSD).shiftedBy(-baseToken.decimals);

  console.groupCollapsed('[SINGLE SIDE WITHDRAW] Calc loss in USD : ', lossInUSD.toString());
  console.log('withdrawPrice [ raw: base wei / token wei ] : ', withdrawPrice.toString());
  console.log('lpTokenPriceBase [ raw: base wei / LP wei ] : ', lpTokenPriceBase.toString());
  console.log('baseTokenPriceInUSD : ', baseTokenPriceInUSD.toString());
  console.log(
    'estimatedToken in base : ',
    estimatedTokenInBaseWei.shiftedBy(-baseToken.decimals).toString(),
  );
  console.log(
    'entered amount in base : ',
    enteredAmountInBaseWei.shiftedBy(-baseToken.decimals).toString(),
  );
  console.log('estimatedToken in token wei : ', estimatedTokenInWei.toString());
  console.log('entered amount in LP wei : ', enteredAmountInLPWei.toString());
  console.groupEnd();

  return lossInUSD;
}

// loss in % = 1 - response_token_amount/ (LP_token_amount*LP_token_price/withdraw_price)
function calculateLossPercent(
  enteredAmountInLPWei: BigNumber,
  estimatedTokenInWei: BigNumber,
  { withdrawPrice, lpTokenPriceBase }: { withdrawPrice: BigNumber; lpTokenPriceBase: BigNumber },
) {
  // LP WEI * ( BASE WEI / LP WEI) / ( BASE WEI / TOKEN WEI ) => TOKEN WEI
  const enteredAmountInTokenWei = enteredAmountInLPWei
    .multipliedBy(lpTokenPriceBase)
    .div(withdrawPrice);

  const lossPercent = max(
    BIG_ONE.minus(estimatedTokenInWei.div(enteredAmountInTokenWei)).multipliedBy(100),
    0,
  );
  console.groupCollapsed('[SINGLE SIDE WITHDRAW] Calc loss percent : ', lossPercent.toString());
  console.log('withdrawPrice [raw: base wei / token wei ] : ', withdrawPrice.toString());
  console.log('lpTokenPriceBase [raw: base wei / LP wei ] : ', lpTokenPriceBase.toString());
  console.log('estimated token in token wei : ', estimatedTokenInWei.toString());
  console.log('entered amount in LP wei : ', estimatedTokenInWei.toString());
  console.log('entered amount in token wei : ', enteredAmountInTokenWei.toString());
  console.groupEnd();

  return lossPercent;
}

function clearUpdateFormInterval(intervalIdRef: Ref<NodeJS.Timer | undefined>) {
  intervalIdRef.value ? clearInterval(intervalIdRef.value) : undefined;
  intervalIdRef.value = undefined;
}
